/* Design altered from https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout/Realizing_common_layouts_using_CSS_Grid_Layout */

html {
  background-color: #e0a0e0;
}

.main-head {
    font-size: 20pt;
    padding: 20px;
  }

  header {
    grid-area: header;
    background-color: #FFCE30;
    font-family: "Lucida Console";
  }

  .content {
    grid-area: content;
    background-color: #f4c8f4;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 1px;
  }
  .main-nav {
    grid-area: nav;
    background-color: #f4c8f4;
  }
  // .side {
  //   grid-area: sidebar;
  //   background-color: #DDDDDD;
  // }

  a:link {
    color: #00a1a1;
    text-decoration: none;
  }
  
  a:visited {
    color: #00a1a1;
    text-decoration: none;
  }
  
  a:hover {
      text-decoration: underline;
      color: #DD0000;
  }

  nav ul {
      list-style-type: none;
  }

  .main-footer {
    grid-area: footer;
    // background-color: #AAAAAA;
  }

  .wrapper {
    display: grid;
    gap: 20px;
    padding-left: 5px;
    padding-right: 5px;
    grid-template-areas:
      "header"
      "nav"
      "content"
      "sidebar"
      "footer";
  }
  @media (min-width: 500px) {
    .wrapper {
      grid-template-columns: 1fr 3fr;
      padding-left: 5px;
      padding-right: 5px;
      grid-template-areas:
        "header  header"
        "nav     nav"
        "content content"
        "footer      footer";
    }
    nav ul {
      display: flex;
      justify-content: space-between;
    }
  }
  @media (min-width: 700px) {
    .wrapper {
      grid-template-columns: 1fr 4fr;
      padding-left: 100px;
      padding-right: 100px;
      grid-template-areas:
        "header header"
        "nav    content"
        "nav    content"
        "footer footer"
     }
     nav ul {
       flex-direction: column;
     }
  }
        